<script setup></script>

<template>
  <div class="container">
    <div id="slipModal" class="modal" @click="closeModal()">
      <div class="modal-content">
        <span class="close">&times;</span>
        <div class="slip-id-modal-container">
          <p class="slip-id-modal-text">Waiting for Slip ID to be scanned...</p>
          <div class="loader"></div>
          <input
            v-model="slipId"
            placeholder="Scan Slip QR Code"
            class="slip-id"
            id="slipId"
            autofocus
            style="opacity: 0"
          />
        </div>
      </div>
    </div>
    <div class="logo-container">
      <img src="./assets/logo.png" class="slip-logo" />
    </div>
    <div class="data-entry-container">
      <div class="slip-entry">
        <button class="enter-slip-button" @click="openModal()">Scan Slip ID</button>
      </div>
      <div class="phone-entry">
        <p class="phone-number-text">Phone Number</p>
        <div class="phone-number-container">
          <div class="phone-flag-box">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/slip-dev1.appspot.com/o/mock-data%2F1f1ec-1f1e7.png?alt=media&token=bb86df08-6138-487e-9137-97da51b00d0d"
              class="phone-flag"
            />+44
          </div>
          <input
            class="phone-text-field"
            placeholder="Enter a phone number"
            v-model="phoneNumber"
          />
        </div>

        <button class="submit-phone-button" @click="submitPhone()">
          Submit phone number
        </button>
      </div>
    </div>
    <div class="help-container">
      <p class="additional-help-text">
        If you need support, please contact us on support@tryslip.com
      </p>
    </div>
    <b-modal id="test"></b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slipId: null,
      phoneNumber: null,
    };
  },

  watch: {
    slipId() {
      const regex = new RegExp(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
      );

      if (regex.test(this.slipId)) {
        this.addSlipId();
      }
    },
  },

  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://cdn.eposnowhq.com/sdk/eposnow.till.sdk.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },

  methods: {
    addSlipId() {
      this.submitEPOSItem("SlipId", this.slipId);
      this.closeModal();
    },

    openModal() {
      var modal = document.getElementById("slipModal");
      modal.style.display = "block";
      this.clearContents();
    },

    closeModal() {
      var modal = document.getElementById("slipModal");
      modal.style.display = "none";
    },

    submitPhone() {
      const cleanedNumber = this.phoneNumber.replace(/^0/, '');
      const ukMobileRegex = /^(7\d{9})$/;
      let validPhone = ukMobileRegex.test(cleanedNumber);
      if (validPhone) {
        this.submitEPOSItem("phone", "44" + cleanedNumber);
      } else {
        alert("Invalid UK mobile number!");
      }
    },

    submitEPOSItem(detailType, detailData) {
      var eposSDK = window.EposNowTillSDK;
      eposSDK.TransactionPublisher.register(window);
      var transaction_data = new eposSDK.Transaction();

      transaction_data.TransactionDetails.push(
        new eposSDK.TransactionDetail(detailType, detailData)
      );

      eposSDK.TransactionPublisher.publish(transaction_data);
      eposSDK.submitForm(window);
    },

    clearContents() {
      this.slipId = "";
      document.getElementById("slipId").focus();
    },

    handleFocus() {
      document.getElementById("slipId").focus();
    },

    handleFocusOut() {
      this.clearContents();
    },
  },
};
</script>

<style scoped>
.container,
.logo-container,
.data-entry-container,
.help-container,
.slip-entry,
.phone-entry {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.container {
  flex-direction: column;
  height: 100%;
  background-color: #ff7b60;
}

.logo-container {
  height: 50%;
}

.data-entry-container {
  margin-top: 70px;
  justify-content: space-between;
}

.data-entry-container,
.help-container {
  margin-top: 70px;
  height: 25%;
}

.slip-entry,
.phone-entry,
.help-container {
  flex-direction: column;
  height: 100%;
  width: 50%;
}

.slip-logo {
  width: 500px;
  height: 350px;
}

.enter-slip-button {
  width: 500px;
  height: 100px;
  color: #ff7b60;
}

.enter-slip-button,
.submit-phone-button {
  border-radius: 12px;
}

.enter-slip-button,
.phone-text-field,
.submit-phone-button {
  border: none;
  background: white;
  box-shadow: 0px 0 8px 0px black;
}

.phone-number-container {
  display: flex;
  flex-direction: row;
  width: 500px;
  box-shadow: 0px 0px 8px 0px black;
  border-radius: 12px;
}

.phone-text-field {
  height: 40px;
  width: 90%;
  text-align: center;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 30px;
  box-shadow: none;
}

.phone-flag-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  border-right: 1px solid #e3e3e3;
  background: white;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height: 40px;
  color: black;
  font-size: 20px;
}

.phone-flag {
  width: 20px;
  margin-right: 5px;
}

.submit-phone-button {
  width: 300px;
  height: 60px;
  margin-top: 10px;
  color: #ff7b60;
}

.submit-phone-button:hover,
.enter-slip-button:hover {
  cursor: pointer;
}

.phone-number-text {
  color: white;
  font-size: 20px;
}

.additional-help-text {
  color: white;
  font-size: 20px;
}

.help-button {
  border: none;
  background: white;
  color: #ff7b60;
  width: 125px;
  height: 40px;
  margin-bottom: 25px;
  border-radius: 12px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  display: flex;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 20%;
}

.close {
  width: 20%;
  color: #aaa;
  font-size: 32px;
  font-weight: bold;
  margin-top: -10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.slip-id-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.slip-id-modal-text {
  text-align: center;
  width: 100%;
  color: black;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
